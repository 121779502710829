<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card class="pl-5 pr-5">
        <!-- head -->
        <b-card-header>
          <b-row no-gutters class="w-100">
            <b-col md="12">
              <div class="d-flex justify-content-end">
                <div>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-popover.hover.focus="
                      '⚪️ Cette fiche concerne l\'enregistrement des clients dans les hôtels et établissements d\'hébergement assimilés  ⚪️ La fiche est remplie par les promoteurs des établissements et leurs agents.'
                    "
                    size="lg"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    title="Information"
                  >
                    <feather-icon icon="BellIcon" />
                  </b-button>
                </div>
                <!-- <div >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    v-b-modal.modal-info
                  >
                    <feather-icon icon="BellIcon" />
                  </b-button>
                </div> -->
              </div>
            </b-col>
            <b-col md="2">
              <b-card-img
                :src="require('@/assets/images/illustration/hébergement.png')"
                alt="Image"
                class="rounded-0 my-2"
              />
            </b-col>
            <b-col
              md="10"
              class="d-flex align-items-center justify-content-center"
            >
              <div>
                <!-- <h1 class="m-auto text-center">FICHE STATISTIQUE</h1> -->
                <br />
                <h4 class="m-auto text-center uppercase">
                  {{ formTitle }}
                  <!-- FICHE D'ENREGISTREMENT DES CLIENTS DE L' HOTEL -->
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <!-- Liste des hôtels -->
        <!-- <b-form-group
          label-cols-md="2"
          label="Nom de l'établissement"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-select
              v-model="infosEnregistrementClients.etablissement"
              :reduce="(option) => option.nomEtablissement"
              type="text"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="listes_hotels"
              label="nomEtablissement"
              value="_id"
              placeholder="Veuillez sélectionner l'hôtel"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->

        <b-row>
          <b-col md="12">
            <!-- Npi -->
            <b-form-group
            label="NPI"
            label-cols-sm="3"
      label-cols-lg="1"
            >
              <!-- rules="length:10" -->
              <validation-provider v-slot="{ errors }">
                <b-form-input
                  v-model="infosEnregistrementClients.npi"
                  v-digits-only="{ length: 10 }"
                  placeholder="Numéro personnel d'identification"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
  <!-- Date d'arrivée -->
  <b-form-group>
    <div class="d-flex align-items-center">
      <label
        for="dateArrivee"
        class="mr-2 col-form-label col-form-label-md"
      >
        Date d' <br>arrivée<RequiredStarComponent />
      </label>
      <validation-provider v-slot="{ errors }" rules="required|beforeOrToday">
        <b-form-input
          v-model="infosEnregistrementClients.dateArrivee"
          lang="fr-CA"
          type="date"
          class="form-control"
          placeholder="Date d'arrivée"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </div>
  </b-form-group>
</b-col>

<b-col md="4">
  <!-- Heure -->
  <b-form-group>
    <div class="d-flex align-items-center">
      <label
        for="heureArrivee"
        class="mr-2 col-form-label col-form-label-md"
      >
        Heure<RequiredStarComponent />
      </label>
      <validation-provider v-slot="{ errors }" rules="required">
        <flat-pickr
          v-model="infosEnregistrementClients.heureArrivee"
          class="form-control"
          placeholder="Heure"
          :config="{
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
          }"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </div>
  </b-form-group>
</b-col>

<b-col md="4">
  <!-- Chambre n° -->
  <b-form-group>
    <div class="d-flex align-items-center">
      <label
        for="numeroChambre"
        class="mr-2 col-form-label col-form-label-md"
      >
        Chambre<RequiredStarComponent />
      </label>
      <validation-provider v-slot="{ errors }" rules="required">
        <b-form-input
          v-model="infosEnregistrementClients.numeroChambre"
          placeholder="K12"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </div>
  </b-form-group>
</b-col>
          <!-- Date de départ -->
            <b-form-group>
              <div class="d-flex align-items-center">
              <label
                for="nationalité"
                class="float-left mr-5 mx-auto col-form-label col-form-label-md"
                >Date de<br> départ<RequiredStarComponent class="mr-2"
              /></label>
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required,
                  date_between: [
                    infosEnregistrementClients.dateArrivee,
                    null,
                    false,
                  ],
                }"
                class="float-left ml-2"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.dateDepart"
                  lang="fr-FR"
                  type="date"
                  class="form-control"
                  placeholder="Date de départ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <!-- Nom/Name -->
            <b-form-group
            >
              <div class="d-flex align-items-center">
              <label
                for="nationalité"
                class=" col-form-label col-form-label-md"
                >Nom:<RequiredStarComponent class="mr-3"
              /></label>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                class="mr-3 w-75"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.lastname"
                  placeholder="Nom"
                  
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <!-- Prénoms/First Names -->
            <b-form-group>
              <div class="d-flex align-items-center">
              <label
                for="nationalité"
                class=" col-form-label col-form-label-md"
                >Prénoms :<RequiredStarComponent class="mr-1"
              /></label>
              <!-- rules="required|alpha" -->
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                class="mr-1 w-75"
              >
                <b-form-input
                  v-model="infosEnregistrementClients.firstname"
                  placeholder="Prénoms"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <!-- Sexe -->
            <b-form-group>
              <div class="d-flex align-items-center">
              <label
                for="nationalité"
                class=" col-form-label col-form-label-md"
                >Sexe:<RequiredStarComponent
              /></label>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                class="ml-5"
              >
                <b-form-radio-group
                  v-model="infosEnregistrementClients.sexe"
                  :options="sexe"
                  class="demo-inline-spacing mb-1 ml-1"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <!-- Nom de jeune fille/Maiden Names -->
            <b-form-group
              v-if="infosEnregistrementClients.sexe === 'Féminin'"
              rules="alpha"
            >
              <label
                for="nationalité"
                class="float-left col-form-label col-form-label-md"
                >Nom de jeune fille :
              </label>
              <b-form-input
                v-model="infosEnregistrementClients.maidenname"
                placeholder="Nom de la jeune fille"
                class="float-left ml-2 w-75"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" />
          <b-col md="6" />
        </b-row>
        <!-- Date de naissance/Date of birth day month year -->
        <b-form-group>
          <div class="d-flex align-items-center">
          <label
            for="nationalité"
            class=" col-form-label col-form-label-md"
            >Date de<br> naissance :<RequiredStarComponent class=" "
          /></label>
          <validation-provider
            v-slot="{ errors }"
            rules="required|beforeOrToday|valideBirthdate"
            class=" ml-1 w-75"
          >
            <b-form-input
              v-model="infosEnregistrementClients.dateNaissance"
              class="form-control"
              placeholder="Date de naissance"
              lang="fr-FR"
              type="date"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        </b-form-group>

        <!-- Lieu de naissance/Place of birth -->
        <b-form-group>
          <div class="d-flex align-items-center">
          <label
            for="lieu_naissance"
            class=" col-form-label col-form-label-md"
            >Lieu de<br> naissance :<RequiredStarComponent class=""
          /></label>
          <validation-provider v-slot="{ errors }" rules="required"
          class="ml-1 w-75"
          >
            <b-form-input
              v-model="infosEnregistrementClients.lieuNaissance"
              placeholder="lieu_naissance"
              
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </div>
        </b-form-group>

        <!-- Nationalite/Nationality -->
        <b-form-group>
          <div class="d-flex align-items-center">
          <label
            for="Lieu de naissance"
            class=" col-form-label col-form-label-md"
            >Nationalité :<RequiredStarComponent class=" "
          /></label>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            class=" ml-1 w-75"
          >
            <SelectCountriesList
              v-model="infosEnregistrementClients.nationalite"
              :input-value="infosEnregistrementClients.nationalite"
              :placeholder="'Sélectionner le pays de votre nationalité'"
            />
            <!-- <v-select
              v-model="infosEnregistrementClients.nationalite"
              type="text"
              :options="pays"
              placeholder="Sélectionner le pays de votre nationalité"
            /> -->
            <!-- <b-form-input
              v-model="infosEnregistrementClients.nationalite"
              placeholder="Nationalité"
            /> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        </b-form-group>

        <!-- Lieu de résidence habituelle -->
        <b-form-group>
          <label for="">
            <strong>Lieu de résidence habituelle :</strong>
          </label>
          <!-- Lieu de résidence habituelle (adresse) -->
          <b-form-group label-cols-md="2" label="Adresse">
            <!-- <validation-provider
              v-slot="{ errors }"
              rules="required"
            > -->
            <b-form-input
              v-model="infosEnregistrementClients.lieuResidence.adresse"
              placeholder="L'adresse du lieu de résidence"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group>
          <b-row>
            <!-- Lieu de résidence habituelle (code postal) -->
            <b-col class="md-6">
              <b-form-group label-cols-md="4" label="Code Postal">
                <b-form-input
                  v-model="infosEnregistrementClients.lieuResidence.bp"
                  placeholder="Le code postal du lieu de résidence"
                />
              </b-form-group>
            </b-col>
            <!-- Lieu de résidence habituelle (Pays) -->
            <b-col class="md-6">
              <b-form-group>
                <div class="d-flex align-items-center ">
                <label
                  for="Lieu de naissance"
                  class="float-left col-form-label col-form-label-md   "
                  >Pays:<RequiredStarComponent class=""
                /></label>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  class=" w-55 ml-custom-largea"
                >
                  <SelectCountriesList
                    v-model="infosEnregistrementClients.lieuResidence.pays"
                    :input-value="infosEnregistrementClients.lieuResidence.pays"
                    placeholder="Pays du lieu de résidence"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Lieu de résidence habituelle ( Département) -->
            <b-col class="md-6">
              <b-form-group label-cols-md="4" label="Département">
                <b-form-input
                  v-if="
                    infosEnregistrementClients.lieuResidence.pays !== 'Bénin'
                  "
                  v-model="infosEnregistrementClients.lieuResidence.departement"
                  placeholder="Département du lieu de résidence"
                />
                <span v-else>
                  <v-select
                    v-model="
                      infosEnregistrementClients.lieuResidence.departement
                    "
                    type="text"
                    :options="departement"
                    label="name"
                    placeholder="Département du lieu de résidence"
                    @input="
                      getVilleCommune(
                        infosEnregistrementClients.lieuResidence.departement
                      )
                    "
                  />
                </span>
              </b-form-group>
            </b-col>
            <!-- Lieu de résidence habituelle (Ville/Commune) -->
            <b-col class="md-6">
              <b-form-group label-cols-md="4" label="Ville/Commune :">
                <!-- <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                > -->

                <b-form-input
                  v-if="
                    infosEnregistrementClients.lieuResidence.pays !== 'Bénin'
                  "
                  v-model="
                    infosEnregistrementClients.lieuResidence.villeCommune
                  "
                  placeholder="Ville du lieu de résidence"
                />
                <span v-else>
                  <v-select
                    v-model="
                      infosEnregistrementClients.lieuResidence.villeCommune
                    "
                    :reduce="(option) => option.name"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="ville_commune"
                    label="name"
                    placeholder=" Ville ou Commune du lieu de résidence"
                  />
                </span>

                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Telephone -->
        <b-form-group label-cols-md="2" label="Téléphone">
          <!-- rules="length:8" -->
          <validation-provider v-slot="{ errors }" rules="" class="   ">
            <b-form-input
              v-model="infosEnregistrementClients.telephone"
              v-phonenumber-field
              placeholder="Téléphone"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Adresse email -->
        <b-form-group label-cols-md="2" label="Adresse email">
          <validation-provider v-slot="{ errors }" rules="email" class="">
            <b-form-input
              v-model="infosEnregistrementClients.email"
              placeholder="Adresse email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Catégorie socio professionnelle -->
        <b-form-group>
          <label
            for="Lieu de naissance"
            class="col-form-label col-form-label-md mr-5"
            >Catégorie socio professionnelle :<RequiredStarComponent class=" "
          /></label>
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-radio-group
              v-model="infosEnregistrementClients.categorieSocioPro"
              :options="categories"
              stacked
              value-field="value"
              text-field="text"
              disabled-field="disabled"
              class="ml-custom-large"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-form-group
            v-if="infosEnregistrementClients.categorieSocioPro === 'true'"
            class="mt-1"
          >
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-input
                v-model="infosEnregistrementClients.categorieSocioProAutres"
                placeholder="Autres catégories socio professionnelles"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form-group>

        <!-- Mode principal de transport à l'intérieur du Bénin -->
        <b-form-group>
          <label
            for="Lieu de naissance"
            class="col-form-label col-form-label-md mr-5"
            >Mode principal de transport à l'intérieur du Bénin
            :<RequiredStarComponent class=" "
          /></label>
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-radio-group
              v-model="infosEnregistrementClients.modeTransportInterieurPays"
              :options="modePrincipalTransport"
              class="demo-inline-spacing mb-1 ml-custom-large"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Motif principal du voyage/Purpose of the trip -->
        <b-form-group>
          <label
            for="Lieu de naissance"
            class="col-form-label col-form-label-md mr-5"
            >Motif principal du voyage :<RequiredStarComponent class=" "
          /></label>
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-radio-group
              v-model="infosEnregistrementClients.motifPrincipalVoyage"
              :options="motifPrincipalVoyage"
              stacked
              value-field="value"
              text-field="text"
              disabled-field="disabled"
              class="ml-custom-large"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-form-group
            v-if="infosEnregistrementClients.motifPrincipalVoyage === 'true'"
            class="mt-1"
          >
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-input
                v-model="infosEnregistrementClients.motifPrincipalVoyageAutres"
                placeholder="Autres motifs principaux de voyage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form-group>

        <!-- Question -->
        <b-form-group
          label-cols-md="2"
          label="Allez-vous recevoir une rémunération d'une entité du pays/Lieu visité durant votre séjour ?"
        >
          <RequiredStarComponent class=" " />
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-radio-group
              v-model="infosEnregistrementClients.possibiliteRemuneration"
              :options="question"
              class="demo-inline-spacing mb-1 ml-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Piece d'identite -->
        <b-form-group>
          <label
            for="Lieu de naissance"
            class="col-form-label col-form-label-md mr-5"
            >Piece d'identité :<RequiredStarComponent class=" "
          /></label>
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-radio-group
              v-model="infosEnregistrementClients.pieceIdentite.type"
              :options="pieceIdentite"
              class="demo-inline-spacing mb-1 ml-1"
              value-field="value"
              text-field="text"
              disabled-field="disabled"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- N° de la piece & delivrée le & à -->
        <b-row>
          <!-- N° de la pièce -->
          <div class="d-flex align-items-center">
          <b-col md="6">
            <b-form-group>
              <div class="d-flex align-items-center">
              <label
                for="N° de la pièce"
                class=" col-form-label col-form-label-md "
                >N° de la pièce<RequiredStarComponent class=" "
              /></label>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  v-model="infosEnregistrementClients.pieceIdentite.numeroPiece"
                  type="text"
                  placeholder="Numéro de la pièce"
                  class="w-50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            </b-form-group>
          </b-col>

          <!-- Délivrée le -->
          <b-col md="6">
            <b-form-group>
              <div class="d-flex align-items-center">
              <label
                for="Délivrée le"
                class=" col-form-label col-form-label-md mr-5"
                >Délivrée le<RequiredStarComponent class=" "
              /></label>
              <validation-provider
                v-slot="{ errors }"
                rules="required|beforeOrToday"
                class=""
              >
                <b-form-input
                  v-model="infosEnregistrementClients.pieceIdentite.delivreLe"
                  class="form-control"
                  placeholder="Délivrée le"
                  lang="fr-FR"
                  type="date"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            </b-form-group>
          </b-col>

          <!-- A -->
          <b-col md="6" >
            <b-form-group>
              <div class="d-flex align-items-center">
              <label
                for="Délivrée le"
                class=" col-form-label col-form-label-md "
                >à<RequiredStarComponent class=" "
              /></label>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  v-model="infosEnregistrementClients.pieceIdentite.a"
                  placeholder="à"
                  class=" w-50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            </b-form-group>
          </b-col>
        </div>
        </b-row>

        <!-- Signature -->
        <!-- <b-form-group class="mt-4">
          <h4 class="text-center">
            Signature
          </h4>
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <b-form-file
              v-model="infosEnregistrementClients.signature"
              class="mb-2"
              placeholder="Choisissez signature ou glisser et déposer le ici ..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
      </b-card>

      <!-- Button d'enregistrement -->
      <div class="text-right">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="col-xl-2"
          @click="sendinfosEnregistrementClients()"
        >
          Aperçu avant enregistrement
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormFile,
  BCardHeader,
  BCardImg,
  VBPopover,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
// import axios from 'axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import SelectCountriesList from "@/components/SelectCountriesList.vue";
import utilsService from "@/services/utils/utils.service";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";
import RequiredStarComponent from "@/components/RequiredStarComponent.vue";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormFile,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    SelectCountriesList,
    RequiredStarComponent,
  },

  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },

  data() {
    return {
      formTitle: "",
      sexe: [
        { text: "Masculin", value: "Masculin" },
        { text: "Féminin", value: "Féminin" },
      ],
      categories: [
        { text: "Libéral/indépendant", value: "Libéral/indépendant" },
        { text: "Employé/salarié", value: "Employé/salarié" },
        { text: "Retraité", value: "Retraité" },
        { text: "Autres (A préciser)", value: "true" },
      ],
      motifPrincipalVoyage: [
        {
          text: "Affaires et motifs professionnels",
          value: "Affaires et motifs professionnels",
        },
        {
          text: "Vacances, loisirs et détente",
          value: "Vacances, loisirs et détente",
        },
        {
          text: "Visites à des parents ou à des amis",
          value: "Visites à des parents ou à des amis",
        },
        { text: "Éducation et formation", value: "Éducation et formation" },
        { text: "Santé et soins médicaux", value: "Santé et soins médicaux" },
        { text: "Religion ou pèlerinage", value: "Religion ou pèlerinage" },
        { text: "Achats", value: "Achats" },
        { text: "Transit", value: "Transit" },
        { text: "Autres (A préciser)", value: "true" },
      ],
      modePrincipalTransport: [
        { text: "Aérien", value: "Aérien" },
        { text: "Terrestre", value: "Terrestre" },
        { text: "Par eau", value: "Par eau" },
      ],
      question: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      pieceIdentite: [
        { text: "Carte d'identité", value: "Carte d'identité" },
        { text: "Passeport", value: "Passeport" },
        { text: "Autre", value: "Autre" },
      ],

      ville_commune: [],

      departement: [],

      pays: [],

      listes_hotels: [],
      isLoadingCommunes: false,
      infosEnregistrementClients: {
        etablissement: "",
        npi: "",
        dateArrivee: "",
        heureArrivee: "",
        numeroChambre: "",
        dateDepart: "",
        lastname: "",
        firstname: "",
        maidenname: "",
        sexe: "",
        dateNaissance: "",
        lieuNaissance: "",
        nationalite: "",
        lieuResidence: {
          adresse: "",
          bp: "",
          villeCommune: "",
          departement: "",
          pays: "",
        },
        telephone: "",
        email: "",
        categorieSocioPro: "",
        categorieSocioProAutres: null,
        // autrescategorie: null,
        modeTransportInterieurPays: "",
        motifPrincipalVoyage: "",
        motifPrincipalVoyageAutres: null,
        // autresMotifPrincipalVoyage: null,
        possibiliteRemuneration: null,
        pieceIdentite: {
          numeroPiece: "",
          delivreLe: "",
          a: "",
          type: "",
        },
        // secteur: 'Hotel',
        // pieceIdentite: "Carte d'identité",
        // signature: null,
      },
      categorieSocioProAutres: null,
      motifPrincipalVoyageAutres: null,

      // infosEnregistrementClientsFictif: {
      //   etablissement: 'The Paradise',
      //   npi: '5135135151',
      //   dateArrivee: '2022-08-22',
      //   heureArrivee: '12:00',
      //   numeroChambre: '23',
      //   dateDepart: '2022-08-29',
      //   lastname: 'TestNom',
      //   firstname: 'TestPrénom',
      //   maidenname: 'TestJeuneFille',
      //   sexe: 'Masculin',
      //   dateNaissance: '2022-08-01',
      //   lieuNaissance: 'Cotonou',
      //   nationalite: 'Béninoise',
      //   lieuResidence: {
      //     adresse: 'Agla',
      //     bp: 229,
      //     villeCommune: 'Cotonou, Littoral',
      //     departement: 'Littoral',
      //     pays: 'Bénin',
      //   },
      //   telephone: '955587970',
      //   email: 'test@gmail.com',
      //   categorieSocioPro: 'Employé/salarié',
      //   // autrescategorie: null,
      //   modeTransportInterieurPays: 'Terrestre',
      //   motifPrincipalVoyage: 'Vacances, loisirs et détente',
      //   // autresMotifPrincipalVoyage: null,
      //   possibiliteRemuneration: true,
      //   pieceIdentite: {
      //     numeroPiece: 651651651,
      //     delivrePar: '2022-07-23',
      //     a: 'Parakou',
      //     type: "Carte d'identité",
      //   },
      //   // secteur: 'Hotel',
      //   // pieceIdentite: "Carte d'identité",
      //   // signature: null,
      // },

      name: "",
      required,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        const { slug } = val.params;
        // this.getListEtablissement()
        const { countries, departements, communes } =
          utilsService.currentUserUtils();
        this.formTitle = utilsService.getFormulaireTitleBySlug(slug);

        this.departement = departements;
        // this.ville_commune = communes;
        this.pays = countries;

        const formInput = this.$route.params.data;
        if (formInput) {
          this.isFillingForm = true;
          this.infosEnregistrementClients = { ...formInput };
        }
      },
    },
    // 'infosEnregistrementClients.lieuResidence.pays': {
    //   immediate: false,
    //   deep: true,
    //   async handler(val, old) {
    //     console.log('totoooooo')
    //     if(val) this.infosEnregistrementClients.lieuResidence.departement = ''
    //   },
    // },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  methods: {
    ...mapActions("etablissements", {
      action_findEtablissement: "findEtablissement",
      action_fetchEtablissements: "fetchEtablissements",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    ...mapActions("params", {
      action_loadCommunes: "loadCommunes",
    }),
    // --------------------------------------------------------------
    // getListEtablissement() {
    //   // laod type etablissement
    //   let currentEtablissement = null

    //   // const data = { options: { code: 'grand_public' } }

    //   this.action_getListTypesEtablissement()
    //     .then(response => {
    //       // get correct hotel
    //       response.data.resources.map(element => {
    //         // console.log('element', element)
    //         if (element.code === 'hotels') {
    //           currentEtablissement = element
    //           console.log('currentEtablissement', currentEtablissement._id)
    //         }
    //       })
    //       // load etablissements
    //       if (currentEtablissement) {
    //         const data = {
    //           options: { typeEtablissement: currentEtablissement._id },
    //         }
    //         this.action_fetchEtablissements(data)
    //           .then(response => {
    //             console.log('response: -------------- ', response)
    //             this.listes_hotels = response.data.resources
    //           })
    //           .catch(err => {
    //             console.log('err::: ', err);
    //           })
    //       }
    //     })
    //     .catch(err => {
    //       console.log('err::: ', err);
    //     })
    // },
    getVilleCommune(param) {
      this.isLoadingCommunes = true;

      const query = { name: param };
      this.action_loadCommunes(query)
        .then((res) => {
          this.ville_commune = [
            ...new Set(res.map((e) => e.name || e) || []),
          ].map((e) => ({ name: e }));
          console.log("communes", this.ville_commune);
          this.isLoadingCommunes = false;
        })
        .catch((err) => {
          this.isLoadingCommunes = false;
        });
    },
    sendinfosEnregistrementClients() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          // console.log(' ------ this.infosEnregistrementClients: ------------- ', this.infosEnregistrementClients)
          // const form = this.infosEnregistrementClients

          // if (this.categorieSocioProAutres) this.infosEnregistrementClients.categorieSocioPro = this.categorieSocioProAutres
          // if (this.motifPrincipalVoyageAutres) this.infosEnregistrementClients.motifPrincipalVoyage = this.motifPrincipalVoyageAutres

          const { etablissementId } = utilsService.currentUserUtils();
          const data = {
            ...this.infosEnregistrementClients,
            etablissement: etablissementId,
          };
          console.log("data: ", data);

          this.$emit("onFormSucess", {
            routerParams: { preview: true, data },
          });

          // axios
          //   .post('api/v1/adresses', {
          //   })
          //   .then(response => // console.log('response: ', response))
          //   .catch(error => // console.log(error))
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Champs requis",
              icon: "TriangleIcon",
              // text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.ml-custom-large {
  margin-left: 5rem; /* Adjust this value as needed */
}
.ml-custom-largea {
  margin-left: 11rem; /* Adjust this value as needed */
}

.ml-custom-largeae {
  margin-left: 7rem; /* Adjust this value as needed */
}

.ml3-custom-large {
  margin-left: 2rem; /* Adjust this value as needed */
}

.w-99 {
  width: 88.8%;
  margin-left: 65px;
}

.w-55 {
  width: 65%;
}
</style>
